<template>
  <div
    style="
      box-sizing: border-box;
      width: 100vw;
      height: 100vh;
      overflow: scroll;
    "
      

  >
    <!-- 导航 -->
    <div
      style="
        position: fixed;
        top: 0;
        left: 0;
        height: 3rem;
        width: 100vw;
        z-index: 1;
      "
    >
      <div class="tour-info-content">
        <!-- 场次信息 -->
        <div class="tour-info-session" @click="pullPop">
          <div class="">
            <div class="session-date">{{ concertDetail.sessionName }}</div>
            <div class="ticket-guarantee">
              <span class="session-title"
                >{{ concertDetail.cityName }}{{ concertDetail.showName }}</span
              >
            </div>
          </div>
          <div class="" style="border-right: 1px solid #eaeaea">
            <span></span>
          </div>
        </div>

        <!-- 票数 -->
        <div class="tour-info-count" @click="openShowNumberPop">
          <span class="price">{{ ticketCount }}</span>
          <span class="suffix" style="margin-right: 0.8rem">张</span>
          <span></span>
        </div>
      </div>
    </div>
    <!-- <div
         @mousewheel="handleWheel"
        class="container"
        v-html="siteData.svg"
        ref="htmlContainer"
        style="background-color: skyblue; cursor: grab;overflow: auto;height: fit-content;width: fit-content;"
      ></div> -->
    <!-- svg -->
    <div
     @mousewheel="handleWheel"
      style="position: relative; width: 100vw;background-color: lavenderblush;height: 100vh;overflow: auto;"
      class="container"
    >
      <div
        class="svg"
        v-html="siteData.svg"
        ref="htmlContainer"
        style="padding-top: 5rem;"
      ></div>
      <svg
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: scroll;
        "
      >
        <g
          v-for="(item, index) in rect"
          :key="index"
          class="text-container"
          @click="selectSite(item)"
        >
          <polygon
            :points="item.point"
            style="stroke-width: 1"
            :class="item.id === selectPrice ? 'active-bg' : 'bg'"
          />
          <text
            :x="item.x"
            :y="item.y"
            font-family="Arial"
            font-size="12"
            :class="item.id === selectPrice ? 'active-cl' : 'cl'"
          >
            {{ item.price }}
          </text>
        </g>
      </svg>
    </div>

    <!-- 票面 -->
    <div
      v-if="showSessionPop"
      style="position: fixed; bottom: 0; left: 0; z-index: 1; width: 100vw"
    >
      <!-- mask -->
      <div class="mask" @click="closePop"></div>
      <!-- 弹窗内容 -->
      <div class="pop-container">
        <div class="pop-content">
          <!-- content -->
          <div class="common-question-pop">
            <!-- title -->
            <div class="pop-title">
              <span class="pop-close" @click="closePop">
                <span>x</span>
              </span>
            </div>
            <!-- 选择场次 -->
            <div class="btn-buy" style="padding: 0 1rem">
              <div>
                <!-- title -->
                <div class="" style="font-size: 1.1rem; padding: 0.5rem 0">
                  选择场次
                </div>
                <div class="pop-list">
                  <div class="pop-list-item">
                    <!-- 信息 -->
                    <div
                      class="pop-item-info"
                      v-for="(item, index) in sessionData"
                      :key="index"
                      @click="selectSession($event, item)"
                      :class="
                        item.sessionId === selectedSession
                          ? 'active-border'
                          : ''
                      "
                    >
                      <div
                        class=""
                        style="display: flex; justify-content: space-between"
                      >
                        <div class="pop-info-date">
                          <div
                            class="pop-info-rule"
                            style="
                              font-size: 0.9rem;
                              font-weight: 700;
                              color: #323038;
                            "
                          >
                            {{ item.sessionName }}
                          </div>
                          <div
                            class="pop-info-rule"
                            style="
                              color: #95949d;
                              font-size: 0.7rem;
                              margin-top: 0.3rem;
                            "
                            @click="showNotePop"
                          >
                            <span
                              v-for="(childItem, index) in item.serviceNoteTags"
                              :key="index"
                            >
                              {{ childItem.noteName
                              }}<span style="padding: 0 0.1rem">|</span>
                            </span>
                          </div>
                        </div>
                        <!-- 价格 -->
                        <div class="pop-item-price">
                          <div class="item-price-container">
                            <div class="item-price">
                              <span class="suffix"> ¥ </span>
                              <span class="price">
                                {{ item.sessionMinPrice }}</span
                              >
                              <span class="suffix">起</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 按钮 -->
            <div>
              <div class="btn-sure" @click="makeSureBtn">确定</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 张数 -->
    <div
      v-if="showNumberPop"
      style="position: fixed; bottom: 0; left: 0; z-index: 1; width: 100vw"
    >
      <!-- mask -->
      <div class="mask" @click="closeNumberPop"></div>
      <!-- 弹窗内容 -->
      <div class="pop-container">
        <div class="pop-content">
          <!-- content -->
          <div class="common-question-pop">
            <!-- title -->
            <div class="pop-title" @click="closeNumberPop">
              <span class="pop-close">
                <div>x</div>
              </span>
            </div>
            <!-- 选择场次 -->
            <div class="btn-buy" style="padding: 0 1rem">
              <div>
                <!-- title -->
                <div class="" style="font-size: 1.1rem; padding: 0.5rem 0">
                  <span>选择张数</span>
                  <span class="ticket-guarantee">同订单3张及以内保证连座</span>
                </div>
                <div class="pop-list">
                  <div class="pop-list-item">
                    <!-- 信息 -->
                    <div
                      class="pop-item-info"
                      v-for="(item, index) in concertDetail.limitation"
                      :key="index"
                      @click="selectNumber(index)"
                      :class="index === selectedNumber ? 'active-border' : ''"
                    >
                      {{ index + 1 }}张
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 购票 -->
    <div
      style="
        position: fixed;
        bottom: 0;
        left: 0;
        width: fit-content;
        height: fit-content;
        width: 100vw;
        background-color: #ff1d41;
      "
      v-if="seatDetail"
    >
      <!-- 弹窗内容 -->
      <div class="pop-container">
        <div class="pop-content">
          <!-- content -->
          <div class="common-question-pop">
            <!-- 选择场次 -->
            <div class="btn-buy" style="padding: 0 1rem">
              <div>
                <!-- title -->
                <div
                  class=""
                  style="
                    font-size: 0.85rem;
                    padding: 0.5rem 0 1.5rem;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="
                      font-weight: 600;
                      color: #ff1d41;
                      border: 1px solid #ff1d41;
                      padding: 0.2rem 0.7rem;
                      border-radius: 1rem;
                    "
                  >
                    <span style="margin-right: 0.5rem"
                      >{{ sectorData.sectorName
                      }}{{ sectorData.zoneName }}</span
                    ><span @click="closeSeatPop">x</span>
                  </div>
                  <div
                    style="
                      font-size: 0.7rem;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span :style="{color: sortType=='price'?'#ff1d41':''}" @click="changeSorting('price')">价格优先</span
                    ><span style="margin: 0 0.3rem">|</span
                    ><span :style="{color: sortType=='seat'?'#ff1d41':''}" @click="changeSorting('seat')">位置优先</span>
                  </div>
                </div>
                <div class="pop-list">
                  <div class="pop-list-item">
                    <!-- 信息 -->
                    <div
                      class="pop-item-info"
                      v-for="(item, index) in seatData"
                      :key="index"
                    >
                      <div
                        class=""
                        style="display: flex; justify-content: space-between"
                      >
                        <div class="pop-info-date">
                          <div
                            class="pop-info-rule"
                            style="
                              font-size: 0.9rem;
                              font-weight: 700;
                              color: #323038;
                            "
                          >
                            <div>{{ item.ticketSeatDesc }}</div>
                            <div>
                              <div
                                class="item-price-container"
                                style="display: flex"
                              >
                                <div class="item-price">
                                  <span class="suffix"> ¥ </span>
                                  <span class="price"> {{ item.price }}</span>
                                  <span class="suffix">/张</span>
                                </div>
                                <div
                                  style="
                                    font-size: 0.6rem;
                                    margin: 0.5rem 0 0 0.5rem;
                                    display: flex;
                                    align-items: center;
                                    border: 1px solid #cc9900;
                                    color: #cc9900;
                                    font-weight: 500;
                                    height: fit-content;
                                  "
                                >
                                  {{ item.sellerTypeName }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- tip -->
                          <div
                            class="pop-info-rule"
                            style="
                              color: #95949d;
                              font-size: 0.7rem;
                              margin-top: 0.3rem;
                              display: flex;
                            "
                            @click="showNotePop"
                          >
                            <div
                              v-for="(childItem, index) in item.serviceNoteTags"
                              :key="index"
                            >
                              {{ childItem.noteName }}
                              <span>
                                <span
                                  style="padding: 0 0.1rem"
                                  v-if="
                                    index != item.serviceNoteTags.length - 1
                                  "
                                  >|</span
                                >
                                <span style="padding: 0 0.1rem" v-else>></span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- 购买 -->
                        <div class="pop-item-price">
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              align-items: center;
                              margin-top: 1rem;
                            "
                          >
                            <div
                              style="
                                background-color: #ff1d41;
                                width: fit-content;
                                color: #fff;
                                font-size: 0.8rem;
                                padding: 0.3rem 0.8rem;
                                border-radius: 1rem;
                                margin-bottom: 0.3rem;
                              "
                              @click="getIntoComfirmOrder(item)"
                            >
                              购买
                            </div>
                            <!-- <div style="
                                font-size: 0.5rem;
                                color: #fb5200;
                                background-color: #fff3ee;
                              ">
                              当前价格仅剩2张
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import axios from "axios";
export default {
  data() {
    return {
      siteData: [],
      scale: 1, // 初始缩放比例
      minScale: 0.5,
      maxScale: 2,
      positionData: [],
      positionArr: [],
      rect: [],
      selectPrice: "",
      sessionData: [],
      selectedSession: "",
      // selectedSession: "1815722685128855554",
      showSessionPop: false,
      selectedNumber: 0,
      showNumberPop: false,
      seatDetail: false,
      concertDetail: {},
      ticketCount: 1,
      seatData: [],
      sectorData: {},
      showId:'',
      // showId:'1815704361531691010',
      sortType:'price',
      zoneCode:'',
     sectorCode:''
    };
  },
  created() {
    const query = window.location.search.substring(1);
    console.log(query, "query");
    const params = {};
    query.split("&").forEach(function(part) {
        const item = part.split("=");
        params[item[0]] = decodeURIComponent(item[1]);
    });
if (query) {
  
  this.selectedSession=params.sessionId
    this.showId=params.showId
    this.ticketCount=params.ticketCount
}
    // svg底图
   this.getSVG()

    // 座位
    this.getSite()
  
    // 场次
    this.getSession()
    // 详情
   this.getSessionDetail()
  },
  methods: {
    // ctest(event) {
    //   console.log(event.target, "sbg");
    //   this.seatDetail = true;
    // },
    // svg
    getSVG(){
      let that = this
      axios
      .get(
        `https://api.zpptickets.com/showApi/venueConcretes?showSessionId=${that.selectedSession}&showId=${that.showId}`
      )
      .then((response) => {
        console.log(response, "res");
        this.siteData = response.data.data;

      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
      });
    },
    // 座位
    getSite(){
      let that = this
      axios
      .get(
        `https://api.zpptickets.com/showApi/zones?showSessionId=${that.selectedSession}&showId=${that.showId}`
      )
      .then((response) => {
        this.positionData = response.data.data;
        this.positionArr = this.positionData.filter((e) => {
          return e.supportSeatPicking === true;
        });
        this.$nextTick(() => {
          this.positionArr.forEach((e) => {
            console.log(e,'eeeeeee');
            
            const element = document
              .getElementById(e.zoneCode)
              .querySelector("*");
            element.style.fill = "#FFE498";
            const rect = element.getBoundingClientRect();
            let y = rect.top;
            let x = rect.left;
            let price = "￥" + e.minPrice;
            let sectorCode =e.sectorCode
            // let zoneCode = e.zoneCode ;
            let point = `${x},${y - 15} ${x + 50},${y - 15} ${x + 50},${
              y + 5
            } ${x + 30},${y + 5} ${x + 25},${y + 10} ${x + 20},${y + 5} ${x},${
              y + 5
            }`;
            this.rect.push({ x, y, price, point, id: e.zoneCode,sectorCode });
          });
        });
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
      });
    },
    // 选择场次
    getSession(){
      axios
      .get(`https://api.zpptickets.com/showApi/sessionone?showId=${this.showId}`)
      .then((response) => {
        this.sessionData = response.data.data;
        console.log(response.data.data, "xxx");
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
      });
    },
    // 详情
    getSessionDetail(){
      axios
      .get(
        `https://api.zpptickets.com/showApi/find_session_detail?showSessionId=${this.selectedSession}`
      )
      .then((response) => {
        this.concertDetail = response.data.data;
        console.log(response.data.data, "xyyyxx");
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
      });
    },
    // 选座
    selectSite(item) {
      console.log(item, "yxr");
      if (this.selectPrice == item.id) {
        console.log("1");
        this.seatDetail = false;
        this.selectPrice = "";
        this.positionArr.forEach((e) => {
            const targetSvg = document
              .getElementById(e.zoneCode)
              .querySelector("*");
            targetSvg.style.fill = "#FFE498";
          
        });
      } else {
        this.seatDetail = true;
        this.selectPrice = item.id;
        this.zoneCode = item.id;
        this.sectorCode = item.sectorCode;
        this.positionArr.forEach((e) => {
          if (item.id === e.zoneCode) {
            const targetSvg = document
              .getElementById(e.zoneCode)
              .querySelector("*");
            targetSvg.style.fill = "#ff617b";
          } else {
            const targetSvg = document
              .getElementById(e.zoneCode)
              .querySelector("*");
            targetSvg.style.fill = "#FFE498";
          }
        });
        // 座位票价信息
        this.getTicketSite();

        
      }
    },
     // 座位票价信息
    getTicketSite(){
      axios
          .get(
            `https://api.zpptickets.com/showApi/zone_tickets?showSessionOID=${this.selectedSession}&ticketNumber=${this.ticketCount}&zoneCode=${this.zoneCode}&sectorCode=${this.sectorCode}&sorting=${this.sortType}&length=10&offset=0`
          )
          .then((response) => {
            console.log(response.data, "ticket");
            this.seatData = response.data.data;
            this.sectorData = this.seatData[0];
          })
          .catch(function (error) {
            console.error("Error fetching data:", error);
          });
    },
    changeSorting(type){
      if (this.sortType!=type) {
        this.sortType = type
      }
      this.getTicketSite();
    },
    // 放大缩小
    handleWheel(event) {
      event.preventDefault(); // 阻止默认滚动行为
      const delta = event.deltaY;
      if (delta > 0) {
        // 向下滚动，缩小
        this.scale = Math.max(this.minScale, this.scale - 0.1);
      } else {
        // 向上滚动，放大
        this.scale = Math.min(this.maxScale, this.scale + 0.1);
      }
      this.applyTransform();
    },

    applyTransform() {
      const cElement = document.querySelector(".container");
      if (cElement) {
        cElement.style.transform = `scale(${this.scale})`;
      }
    },
    // 确定场次
    makeSureBtn() {
      this.showSessionPop = false;
      this.getSVG()
      this.getSite()
      this.getSessionDetail()
      console.log("确定");
    },
    showNotePop(data) {
      console.log("showNote", data);
    },
    // 选择场次
    selectSession(e, data) {
      console.log("session", e, data);
      this.selectedSession = data.sessionId;
    },
    closeSeatPop() {
      this.seatDetail = false;
      this.selectPrice = "";
      this.positionArr.forEach((e) => {
        const targetSvg = document
          .getElementById(e.zoneCode)
          .querySelector("*");
        targetSvg.style.fill = "#FFE498";
      });
    },
    // 拉起场次弹窗
    pullPop() {
      console.log("pullPop");
      this.showSessionPop = true;
    },
    // 关闭场次弹窗
    closePop() {
      console.log("closePop");
      this.showSessionPop = false;
      this.popType = "";
    },

    // 拉起选择张数弹窗
    openShowNumberPop(e) {
      console.log(e, "openShowNumberPop");
      this.showNumberPop = true;
    },

    // 关闭选择张数弹窗
    closeNumberPop() {
      console.log("closeNumberPop");
      this.showNumberPop = false;
    },
    // 确定票数
    selectNumber(data) {
      console.log(data, "selectNumber");
      this.ticketCount = data + 1;
      this.selectedNumber = data;
      this.showNumberPop = false;
      this.getSVG()

      this.getSite()
    },
    getIntoComfirmOrder(data) {
      console.log(data,'xuanzuo');
      
      let param = {
					count: this.ticketCount,
					sessionOID: this.selectedSession,
					showOID: this.showId,
					ticketOID:data.ticketOID,
					seatPlanOID: data.seatPlanOID,
          originalPrice:data.sectorZoneName+' '+data.ticketSeatDesc

				}
        console.log(param);
        
				let url =
					`/buyTicket/comfirmOrder/comfirmOrder?count=${param.count}&seatPlanOID=${param.seatPlanOID}&sessionOID=${param.sessionOID}&showOID=${param.showOID}&ticketOID=${param.ticketOID}&originalPrice=${param.originalPrice}`
          console.log(url,wx);
          
      wx.miniProgram.navigateTo({
        url, // 替换成你想要跳转的小程序页面路径
      });
    },
  },
};
</script>

<style>
* {
margin: 0;
padding: 0;
}
.svg-container {
  width: fit-content;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 0, 0.3);
}

.svg-container svg {
  transition: transform 0.3s ease;
}

.active-bg {
  fill: #ff1d41;
  stroke: #fff;
}

.bg {
  fill: #fff;
  stroke: black;
}

.active-cl {
  fill: #fff;
}

.cl {
  fill: #000;
}

.tour-info-content {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2.5rem;
  margin: 1rem 1rem;
  border-radius: 3rem;
  box-shadow: 0px 5px 21.3px 0px rgba(0, 0, 0, 0.06);
  height: 2.6rem;
}

.back-btn {
  width: 2rem;
}

.tour-info-session {
  display: flex;
  /* flex: 1; */
  justify-content: space-between;
  align-items: center;
}

.session-date {
  color: #333;
  font-size: 0.9rem;
  font-weight: bold;
}

.ticket-guarantee {
  display: flex;
  align-items: center;
  color: #95949d;
  font-size: 0.625rem;
}

.session-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
}

.tour-info-count {
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}

/* 场次弹窗*/
.mask {
  transition-duration: 250ms;
  transition-timing-function: ease-out;
  position: fixed;
  inset: 0px;
  z-index: 10070;
  background-color: rgba(0, 0, 0, 0.5);
}

.pop-container {
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  z-index: 10075;
  position: fixed;
  display: flex;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.pop-content {
  flex: 1 1 0%;
  background-color: transparent;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.common-question-pop {
  background: #ffffff;
  border-radius: 0.5rem 0.5rem 0 0;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 0 0 1.5rem;
}

.pop-title {
  padding: 0.9375rem 1.5rem 0.625rem;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  color: #333333;
  line-height: 1.5625rem;
  position: relative;
  min-height: 2.5rem;
  box-sizing: border-box;
}

.pop-buy-title {
  display: flex;
  justify-content: space-between;
  min-height: 2.5rem;
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  color: #333;
}

.pop-close {
  position: absolute;
  right: 0.9375rem;
  top: 50%;
  font-size: 0.875rem;
  transform: translateY(-50%);
}

.pop-text {
  padding: 0.625rem 1.5rem;
  font-weight: 400;
  color: #333333;
  line-height: 1.25rem;
  font-size: 0.75rem;
  max-height: 50vh;
  overflow: hidden auto;
}

.pop-list {
  flex: 1;
  flex-shrink: 1;
  padding-bottom: 1.5rem;
  overflow-y: scroll;
  height: 20rem;
}

/* 购买 */
.pop-item-info {
  border: 1px solid #eaeaea;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  background-color: #fff;
}

.active-border {
  border-color: #ff1d41;
  background-color: #fffbfb;
}

.pop-item-price {
  text-align: right;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding-left: 0.625rem;
  margin-top: -0.125rem;
}

.price {
  color: #ff1d41;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0 0.1rem;
}

.suffix {
  color: #ff1d41;
  font-family: PingFang SC;
  font-size: 0.625rem;
  font-weight: 400;
}

.btn-sure {
  background-color: #ff1d41;
  color: #fff;
  width: 90%;
  margin: 1rem auto 0;
  text-align: center;
  padding: 0.6rem 0;
  border-radius: 2rem;
}

::-webkit-scrollbar {
  display: none;
  /* 隐藏滚动条 */
}
</style>